body{
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

h3{
  margin: 0;
  padding: 0;
}

h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: rgba(20, 28, 37, 1);
  margin: 0px 0px 10px 0px;
}

p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #21273c;
}

.horizontal-container {
  padding-left: 3rem;
  padding-right: 3rem;
}

.body-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.fadeIn{
  opacity: 0;
  transform: translateY(-15px);
  animation: fadeIn 250ms forwards ease-out;
}

.card-box.details{
  background-color: white;
}

.card-box {
  background-color: rgba(232, 233, 237, 1);
  border-radius: 8px;
  padding: 10px;
  margin: 1rem .75rem;

  .header {
    margin-bottom: 10px;
    display: flex;
    gap: .5rem;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 0px 10px 10px;

    h3 {
      margin: 0;
      font-weight: 600;
      font-size: 16px;
      color: #000;
      display: inline-block;
      text-transform: uppercase;
    }

    span {
      background-color: #FFF;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #000;
      text-align: center;
      padding:4px 8px;
      border-radius: 3px;
    }
  }
}

.markdown-content {
  p{
    max-width: 100ch;
    word-wrap: break-word;
  }
  p, ul li {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #21273c;
  }

  ul {
    padding-inline-start: 20px;
  }

  img {
    max-width: 100%;
  }
}
