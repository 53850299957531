.component-board-task {
  padding-inline: 1rem;
  .linker {
    text-decoration: none;

    .task-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-shadow: 6px 6px 10px 0px rgba(78, 83, 102, 0.1);
      background-color: #FFF;
      transition: transform 250ms ease-out, background 250ms ease-out;
      padding: 1rem;
      min-height: 60px;
      border-radius: 8px;
      margin:1.5rem 1rem;

      .date{
        display: flex;
        gap: .25rem;
      }

      .title-content {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-start;
        column-gap: 10px;

        h4.title {
          font-weight: 600;
          font-size: 12px;
          line-height: 17px;
          margin: 0;
          color: #000;
        }

        h4.id-label {
          background-color: rgba(227, 233, 143, 1);
          font-weight: 600;
          font-size: 12px;
          padding: 1px 5px;
          border-radius: 8px;
          color: #000;
          margin: 0;
        }
      }

      .tags-content {
        margin: 10px 0px;
      }

      .bottom-content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;
        column-gap: 20px;

        p.date-label {
          margin: 0px;
          font-weight: 600;
          font-size: .6rem;
          color: rgba(133, 142, 176, 1);
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 5px;

          img {
            height: 12px;
          }
        }
      }
    }


    &:hover {
      .task-box {
        background-color: #ececec;
        transform: scale(1.01);

        h4 {
          color: #76777d;
        }
      }
    }
  }
}
