.screen-board {
  .content-column-task {
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;

    .item {
      width: 100%;
      min-width: 27rem;
    }
  }
}
