.navbar {
  height: 60px;
  background-color: #141c25;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;

   h1 {
    margin: 0;
    font-size: 20px;
    line-height: 30px;
    color: white;

    span {
      font-size: 12px;
      font-weight: 500;
      margin-left: 5px;
    }
  }
}




