.labels{
  width: 100%;
  opacity: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 0.5rem;
  transition: transform 250ms ease-out;
  animation-delay: 75ms;
  animation: fadeIn 250ms forwards;
  transform: translateY(-15px);
  row-gap: 0.5rem;

  & > .label{
    background-color: rgba(239, 232, 252, 1);
    color: rgba(125, 66, 236, 1);
    font-weight: 600;
    font-size: 12px;
    padding: 2px 8px;
    border-radius: 8px;
  }
}

@keyframes fadeIn {
  to{
    opacity: 1;
    transform: translateY(0);
  }
}
