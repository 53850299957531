.component-board-column {
  .content-task-column {
    height: calc(100vh - 250px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media (max-width: 1000px) {
  .component-board-column {
    width: 350px;
  }
}

@media (max-width: 600px) {
  .component-board-column {
    width: 350px;
  }
}
