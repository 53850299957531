.screen-issue {
  h2{
    font-size: 1.3rem;

    span {
      background-color: #e3e98f;
      font-weight: 600;
      font-size: 1rem;
      padding: 0.1rem 0.5rem;
      border-radius: 8px;
      color: #000;
      margin-right: 0.5rem;
    }
  }

  .title-labels-box{
    animation-delay: 50ms;
    opacity: 0;
    width: 100%;
  }

  .main-info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .no-labels{
      font-weight: bold;
      color: #7d42ec;
    }

    & > div{
      display: flex;
      padding-bottom: 1rem;
      gap: 1rem;

      img{
        margin-right: 1vw;
      }

      & > div:first-of-type{
        width: 10vw;
      }

      & > div:last-of-type{
        width: 100%;
      }
    }

  }
  .dates-box {
    display: flex;
    animation-delay: 100ms;
    column-gap: 20px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    span {
      display: inline-block;
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;

      &[class="date"]{
        color: #a4a4a4
      }
    }
  }

  .comment-box {

    margin-top: 30px;

    .author-container{
      display: flex;
    }

    .comment-header{
      display: flex;
      gap: .5rem;
    }

    h4.author {
      margin: 0;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      color: #21273c;
      position: relative;
      padding-left: 1rem;

      &::before{
        content: '';
        position: absolute;
        left: -3px;
        top: 5px;
        background-color: #e4f04e;
        border-radius: 10rem;
        height: 10px;
        width: 10px;
      }
    }

    .body {
      padding: 0px 20px;
      position: relative;
      &::before{
        content:'';
        display: block;
        position: absolute;
        left: 0;
        background-color: rgba(228, 240, 78, 1);
        width: 2px;
        height: 100%;
      }
    }

    & > div{
      gap: .25rem;
      align-items: center;
    }
  }
}

.issue-info-container{
 display: flex;
 gap: 2rem;
 border-radius: 1rem;
 background-color: #FFF;

 .card-box{
  box-shadow: 6px 6px 10px 0px rgba(78, 83, 102, 0.1);
  padding-inline: 1.5rem;
 }

 h3.title{
  text-transform: uppercase;
  padding-inline: 1rem;
 }

  .issue-description-comments-container{
    display:flex;
    flex-direction: column;
    width: 65%;
    min-height: 150px;
    background-color: #e8e9ed;
    padding:1rem;
    border-radius: 1rem;

    .comments-title{
      margin-top: 3rem;
      display: flex;
      align-items: center;
      gap: 1vw;

      span{
        background-color: #ffffff;
        color: #000;
        font-weight: bold;
        padding: 5px 12px;
      }
    }

    .title{
      color: rgba(23, 23, 23, 1);
      text-transform: uppercase;
    }

    .comments{
      min-height: 150px;

      .no-comments{
        display: grid;
        place-items: center;
      }
    }

    .no-description{
      text-align: center;
    }
  }
  .related-issues-container{
    padding: 1rem;
    background-color: #e8e9ed;
    border-radius: 1rem;
    width: 40%;

      .no-related-issues{
        display: grid;
        justify-content: center;
        padding-block:2rem;
        height: 90%;
        border-radius: .5rem;
      }
  }
  & .header > .title{
    text-transform: uppercase;
  }
}

